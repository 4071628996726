.container{
    height: fit-content;
    width: 100vw;
    background-color: #191919;
    color: #fff;
    padding: 60px 0;
    box-sizing: border-box;
    font-size: 30px;
    overflow: hidden;
}
.main{
    max-width: 1240px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 20px;
    box-sizing: border-box;
}
.gigmile_logo{
    max-width: 150px;
}
.main_top{
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 50px;
}
.mainLinksContainer{
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: flex-end;
}
.linksContainer{
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 150px;
}
.linksContainer h3{
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    color: #fff;
}
.linksContainer a{
    color: #D2CFCF;
    margin: 0;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
}
.main_top button{
    height: 50px;
    background-color: #fff;
    height: 50px;
    width: 150px;
    color: #000;
    padding: 0 25px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    font-weight: 500;
    font-size: 14px;
    font-weight: 500;
}
.main_bottom{
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 50px;
    margin-top: 40px;
}
.getApp h3{
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin: 0;
    line-height: 23px;
}
.getApp p{
    color: #fff;
    font-weight: 400;
    margin: 0;
    margin-top: 10px;
    font-size: 15px;
    line-height: 24px;
    max-width: 334px;
}
.getApp img{
    height: 45px;
    margin-top: 30px;
    cursor: pointer;
}
.bottom_2{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 67px;
}
.copyright{
    font-size: 14px;
    font-weight: 400;
    color: #D2CFCF;
    font-weight: 400;
    margin: 0;
}
.links{
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
}
.links img{
    height: 15px;
}

/****** responsive styling *******/
@media (max-width: 1200px) {
    .main_top{
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
    .mainLinksContainer{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }
    .linksContainer{
        width: 100%;
    }
    .main_bottom{
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .bottom_2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
    }
}