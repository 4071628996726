.container{
    width: 100%;
    padding: 50px 0;
    padding-bottom: 100px;
}
.container h1{
    font-size: 64px;
    line-height: 78px;
    color: #000;
    margin: 0;
    font-weight: 600;
    max-width: 700px;
}
.topContainer{
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}
.topContainer p{
    margin: 0;
    font-size: 16px;
    max-width: 750px;
    line-height: 28px;
    margin-top: 15px;
}
.bottom_grid{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: fit-content;
}
.sdgItem{
    height: 187px;
    width: 187px;
    object-fit: cover;
}
.GoalItem{
    height: 100%;
    background: #fff;
    border-radius: 40px;
    padding: 30px;
    box-sizing: border-box;
    min-width: 410px;
}
.GoalItem h3{
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    width: 90%;
    line-height: 26px;
}
.GoalItem p{
    margin: 0;
    color: #4f4a4a;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 5px;
}
.GoalItem_dark{
    background: #191919;
}
.GoalItem_dark h3, .GoalItem_dark p{
    color: #fff;
}
@media (max-width: 1000px) {
    .container h1{
        font-size: 48px;
        line-height: 62px;
    }
    .topContainer{
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .bottom_grid{
        padding: 0 20px;
        box-sizing: border-box;
    }
}