.container{
    background-color: #f7f7f7;
    min-height: 100vh;
    width: 100vw;
    overflow-x: hidden;
}
.testimonial_container{
    padding: 0 20px;
    box-sizing: border-box;
}
.testimonial_banner{
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    margin-bottom: 144px;
    display: grid;
    grid-template-columns: 1fr 1.3fr;
    height: 490px;
    background: #FFFFFF;
    border-radius: 58px;
    overflow: hidden;
}
.testimonial_banner img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.testimonial_banner_content{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 50px;
    box-sizing: border-box;
}
.testimonial_banner_content h3{
    margin: 0;
    font-weight: 600;
    font-size: 24px;
}
.maincontents{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-top: 20px;
}
.maincontents div{
    width: 100%;
}
.maincontents div p{
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
.maincontents div h2{
    margin: 0;
    font-weight: 600;
    font-size: 30px;
    margin-top: 8px;
    line-height: 40px;
}
.maincontents button{
    height: 50px;
    width: fit-content;
    background-color: var(--gigmileRed);
    color: #fff;
    padding: 0 40px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    font-weight: 500;
    font-size: 14px;
    margin-top: 15px;
}

@media (max-width: 800px) {
    .testimonial_banner{
        display: flex;
        flex-direction: column;
        height: fit-content;
    }
    .testimonial_banner img{
        height: 300px;
    }
    .testimonial_banner_content{
        height: fit-content;
        padding: 30px;
    }
    .maincontents{
        display: flex;
        flex-direction: column;
    }
}