.container{
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding: 100px 0px;
}
.container h1{
    font-size: 64px;
    font-weight: 600;
    color: #fff;
    margin: 0;
    color: #000;
    max-width: 620px;
}
.flexContainer{
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-top: 50px;
}
.flexItem{
    background-color: #fff;
    min-width: 410px;
    border-radius: 40px;
    padding: 32px 25px;
    box-sizing: border-box;
    height: 100%;
}
.flexItem img{
    height: 75px;
}
.flexItem h3{
    font-weight: 600;
    margin: 0;
    font-size: 18px;
    margin-top: 4px;
}
.flexItem p{
    margin: 0;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 10px;
    color: #4F4A4A;
}
@media (max-width: 1000px) {
    .container h1{
        font-size: 48px;
        line-height: 62px;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .flexContainer{
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    .flexItem{
        min-width: 100%;
    }
}