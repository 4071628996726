.container{
    height: 1200px;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #191919;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 20px;
    box-sizing: border-box;
}
.container p{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}
.container h1{
    font-size: 64px;
    margin: 0;
    font-weight: 600;
    line-height: 78px;
    max-width: 917px;
    margin-top: 10px;
    margin-bottom: 37px;
}
@media (max-width: 500px) {
    .container h1{
        font-size: 45px;
        line-height: 65px;
        margin-top: 20px;
    }
}