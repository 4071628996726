.container{
    width: 100vw;
    overflow-x: hidden;
}
.times_square{
    height: 100vh;
    width: 100vw;
    object-fit: cover;
}
.two_grids{
    display: grid;
    grid-template-columns: 470px 1fr;
    gap: 120px;
    max-width: 1240px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 20px;
    box-sizing: border-box;
    align-items: center;
}
.two_grids_image{
    height: 550px;
    width: 100%;
    position: relative;
}
.guy_on_bike{
    height: 100%;
    width: 100%;
    border-radius: 40px;
    background-color: rgb(42, 255, 131);
    object-fit: cover;
}
.thunderBolt1{
    position: absolute;
    top: -20px;
    right: -100px;
}
.thunderBolt2{
    position: absolute;
    top: -70px;
    right: -60px;
}
.two_grids_content h1{
    font-size: 64px;
    line-height: 78px;
    color: #000;
    margin: 0;
    font-weight: 600;
}
.two_grids_content h1 span{
    color: #808080;
}
.two_grids_content p{
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 35px;
    margin-top: 20px;
}
@media (max-width: 900px) {
    .two_grids{
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
}
@media (max-width: 1000px) {
    .two_grids_content h1{
        font-size: 48px;
        line-height: 62px;
    }
}