.container{
    min-height: 100vh;
    width: 100vw;
    background-color: #f7f7f7;
}
.main{
    max-width: 840px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    margin-bottom: 150px;
    padding: 0 20px;
    box-sizing: border-box;
}
.main h1{
    font-weight: 600;
    font-size: 78px;
    line-height: 88px;
    margin: 0;
    text-align: center;
}
.introText{
    font-weight: 500;
    font-size: 24px;
    margin: 0;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.date{
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    text-align: center;
}
.mainContainer{
    margin-top: 60px;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.section{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.section h3{
    margin: 0;
    font-weight: 500;
    font-size: 18px;
}
.section p{
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
@media (max-width: 1000px) {
    .container h1{
        font-size: 48px;
        line-height: 62px;
    }
}