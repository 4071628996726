.container{
    min-height: 100vh;
    background-color: #f7f7f7;
}
.top{
    text-align: center;
    margin-top: 30px;
}
.top h1{
    margin: 0;
    font-weight: 600;
    font-size: 78px;
    line-height: 88px;
    max-width: 850px;
    margin-right: auto;
    margin-left: auto;
}
.top p{
    margin: 0;
    line-height: 28px;
    max-width: 560px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 16px;
}
.filter{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 90px;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
}
.filter div{
    background-color: transparent;
    height: 50px;
    width: fit-content;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #8B8989;
    border-radius: 10px;
}
.filter .activeDiv{
    background-color: #fff;
    color: #000;
    font-weight: 500;
}
.contents_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    gap: 40px;
    padding-bottom: 100px;
}
.BlogItem{
    width: 100%;
    background-color: #fff;
    border-radius: 9px;
    overflow: hidden;
}
.BlogItem img{
    height: 180px;
    width: 100%;
    object-fit: cover;
}
.mainBlogContainer{
    padding: 15px;
    box-sizing: border-box;
}
.mainBlogContainer ul{
    display: flex;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    color: #434343;
}
.mainBlogContainer ul li:nth-child(1){
    list-style: none;
    padding-right: 25px;
}
.tag{
    height: 30px;
    width: fit-content;
    padding: 0 12px;
    box-sizing: border-box;
    background: rgba(243, 244, 245, 0.5);
    border-radius: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #32A071;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
}
.mainBlogContainer h3{
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #434343;
    margin: 0;
    margin-top: 10px;
}
.mainBlogContainer a{
    text-decoration: none;
}
.introText{
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #4F4A4A;
    margin: 0;
    margin-top: 10px;
}
.writer{
    font-weight: 500;
    font-size: 13px;
    line-height: 28px;
    color: #434343;
    margin-top: 15px;
}
@media (max-width: 1200px) {
    .top{
        padding: 0 20px;
        box-sizing: border-box;
    }
    .filter{
        padding: 0 20px;
        box-sizing: border-box;
        flex-wrap: wrap;
    }
    .contents_container{
        padding: 0 20px;
    }
}
@media (max-width: 1000px) {
    .contents_container{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 600px) {
    .contents_container{
        grid-template-columns: repeat(1, 1fr);
    }
}