.container{
    height: fit-content;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 70px;
    width: 100vw;
}
.gammaAppMobile{
    height: 380px;
    width: 100%;
    object-fit: cover;
    display: none;
}
.canva{
    height: fit-content;
    width: 100vw;
    padding: 120px 0px;
    background-color: rgba(0,0,0,0.1);
}
.main{
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    color: #fff;
}
.top{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.top div{
    border-top: 1px solid #fff;
    width: 140px;
}
.main h1{
    max-width: 700px;
    font-weight: 600;
    font-size: 64px;
    line-height: 78px;
    margin: 0;
}
.main p{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    margin-top: 20px;
    max-width: 620px;
    margin-bottom: 40px;
}
@media (max-width: 1000px) {
    .main{
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .canva{
        background-color: rgba(0,0,0,0.3);
    }
    .main h1{
        font-size: 38px;
        line-height: 48px;
    }
}
@media (max-width: 600px) {
    .main{
        padding: 0;
        color: #000;
    }
    .gammaAppMobile{
        display: block;
    }
    .canva{
        background-color: #fff;
        padding: 30px 20px;
        box-sizing: border-box;
    }
    .top div{
        border-color: #000;
        width: 70px;
    }
}