.container{
    height: fit-content;
    padding: 50px 0;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 100px;
}
.container h1{
    font-size: 64px;
    line-height: 78px;
    color: #000;
    margin: 0;
    font-weight: 500;
    max-width: 790px;
}
.container h1 span{
    color: #808080;
}
.officeContainer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-direction: row;
    gap: 30px;
    margin-top: 50px;
}
.Office{
    height: 430px;
    min-width: 100%;
    background-color: rgb(172, 45, 186);
    border-radius: 30px;
    display: flex;
    align-items: flex-end;
    padding: 25px 15px;
    box-sizing: border-box;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.Office div{
    height: fit-content;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    background: rgba(160, 154, 154, 0.25);
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(17.6389px);
    color: #fff;
}
.Office h3{
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin: 0;
}
.Office p{
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    margin-top: 13px;
}
@media (max-width: 1000px) {
    .container h1{
        font-size: 48px;
        line-height: 62px;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .officeContainer{
        padding: 0 20px;
        box-sizing: border-box;
    }
}
@media (max-width: 800px) {
    .officeContainer{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 600px) {
    .officeContainer{
        grid-template-columns: repeat(1, 1fr);
    }
}