.container{
    /* height: 550px; */
    width: 100vw;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr;
    gap: 10px;
    align-items: center;
    padding: 0 50px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    top: -50px;
}
.mobileContainer{
    box-sizing: border-box;
    padding-bottom: 50px;
    padding-top: 50px;
}
.item1, .item2, .item3{
    height: 100%;
    width: 100%;
    position: relative;
    /* overflow: hidden; */
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.slideIn{
    animation: slideIn 0.8s ease forwards;
    display: block;
}
.displayNone{
    display: none;
}
.illustration_1{
    max-height: 600px;
}
.blur{
    height: 100px;
    position: absolute;
    bottom: 0;
    /* background-color: #fff; */
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.55) 59.37%, #FFFFFF 100%);
}
.main1, .main2, .main3{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main1 img{
    height: 250px;
    position: absolute;
    bottom: 50px;
    right: 100px;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
}
.main2, .main3{
    align-items: flex-end;
}
.main2 img{
    position: absolute;
    bottom: 100px;
    box-shadow: 0px 4px 144px rgba(0, 0, 0, 0.35);
    border-radius: 20px;
}
.main3 img{
    position: absolute;
    bottom: 100px;
    box-shadow: 0px 4px 144px rgba(0, 0, 0, 0.35);
    border-radius: 12px;
}

/******* Animations ***********/
.item1 .main1 img, .item2 .main2 img, .item2 .main3 img{
    animation: reverseScale 0.5s ease forwards;
}
.item1:hover .main1 img, .item2:hover .main2 img, .item2:hover .main3 img{
    animation: scale 0.5s ease forwards;
}
@keyframes scale {
    0%{transform: scale(1);}
    100%{transform: scale(1.1);}
}
@keyframes reverseScale {
    0%{transform: scale(1.1);}
    100%{transform: scale(1);}
}
@keyframes slideIn {
    0%{transform: translateX(300px); opacity: 0;}
    100%{transform: translateX(0); opacity: 1;}
}

/********** Responsive styling *********/
@media (max-width: 1000px) {
    .container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        /* background-color: red; */
        padding: 0;
        top: -40px;
    }
    .item1{
        overflow: hidden;
    }
    .illustration_1{
        height: 600px;
        object-fit: cover;
    }
}

/******** Mobile styling ********/