@import url('https://fonts.cdnfonts.com/css/graphik');

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Graphik', sans-serif;
}

:root{
  --gigmileRed: #FC553A;
  --gigmileGreen: #808080;
}