.container{
    width: 100vw;
    overflow-x: hidden;
}
.images{
    display: flex;
    flex-direction: row;
    gap: 20px;
    /* max-width: 1240px; */
    margin-right: auto;
    margin-left: auto;
    /* padding: 50px 20px; */
    padding-top: 50px;
    padding-bottom: 0;
    box-sizing: border-box;
    cursor: grab;
}
.images img{
    object-fit: cover;
    border-radius: 20px;
    background-color: #e1e1e1;
}
.career0{
    height: 300px;
    width: 400px;
}
.career1{
    width: 424.63px;
    height: 248px;
}
.career2{
    width: 275px;
    height: 367px;
}
.career3{
    width: 397.23px;
    height: 297.92px;
}
.career4{
    width: 320px;
    height: 240px;
}
.career5{
    width: 371px;
    height: 400px;
}