.container{
    max-width: 1240px;
    height: fit-content;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 20px;
    box-sizing: border-box;
}
.container h1{
    max-width: 795px;
    font-weight: 600;
    font-size: 64px;
    line-height: 78px;
    margin: 0;
}
.container h1 span{
    color: #808080;
}
.introText{
    max-width: 750px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    margin-top: 10px;
    /* margin-bottom: 20px; */
}
.statsContainer{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 17px;
    margin-top: 46px;
}
.Mission img{
    height: 211px;
    width: 100%;
    background-color: rgb(255, 144, 144);
    border-radius: 20px;
    object-fit: cover;
}
.Mission div{
    margin-top: 40px;
    border-top: 0.5px solid #D9D9D9;
}
.Mission p{
    margin: 0;
    font-size: 18px;
    color: #484747;
    margin-top: 15px;
    font-weight: 500;
}
.Mission h2{
    font-size: 58px;
    line-height: 78px;
    color: #000;
    font-weight: 500;
    margin: 0;
    margin-top: 5px;
}
@media (max-width: 1000px) {
    .container h1{
        font-size: 48px;
        line-height: 62px;
    }
    .statsContainer{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 800px) {
    .statsContainer{
        grid-template-columns: repeat(2, 1fr);
    }
    .Mission div{
        margin-top: 25px;
    }
    .Mission p{
        font-size: 16px;
    }
    .Mission h2{
        font-size: 40px;
    }
}