.container{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 3000;
}
.main{
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
    justify-content: center;
    width: 700px;
    height: 400px;
    padding: 15px;
    box-sizing: border-box;
    animation: slideIn 0.5s ease forwards;
}
.close{
    height: 40px;
    width: 40px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #f7f7f7;
}
.video{
    height: 100%;
    width: 100%;
    border-radius: 10px;
}
@media (max-width: 700px) {
    .main{
        width: 100%;
        height: 300px;
    }
}
@keyframes slideIn {
    0%{transform: translateY(300px); opacity: 0;}
    100%{transform: translateY(0); opacity: 1;}
}