.container{
    width: 100vw;
    background-color: #f7f7f7;
    padding: 50px 0;
    padding-bottom: 150px;
}
.main{
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
}
.main h1{
    font-size: 64px;
    font-weight: 600;
    color: #000;
    margin: 0;
    text-align: center;
}
.introText{
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    margin-top: 10px;
    text-align: center;
}
.mainContainer{
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
}
.filterSection{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 23px;
    align-items: center;
}
.searchBar{
    height: 47px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 30px 1fr;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #fff;
}
.searchBar input{
    height: 100%;
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: 400;
}
.searchBar input:focus{
    outline: none;
}
.searchBar:focus-within{
    border-color: var(--gigmileRed);
}
.filterSection select{
    height: 47px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    border: 0.5px solid #fff;
    padding: 0 10px;
    box-sizing: border-box;
}
.filterSection select:focus{
    outline: none;
    border-color: var(--gigmileRed);
}
.rolesContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}
.roleItem{
    background-color: #fff;
    padding: 20px 30px;
    box-sizing: border-box;
    border-radius: 20px;
}
.roleItem h3{
    font-size: 18px;
    margin: 0;
    font-weight: 600;
    margin: 0;
}
.content{
    line-height: 24px;
    color: #4F4A4A;
    font-size: 15px;
    margin-top: 4px;
    font-weight: 400;
}
.location{
    font-weight: 500;
    font-size: 15px;
    margin: 0;
    color: #4F4A4A;
    margin-top: 10px;
}
.roleItem a{
    color: #000;
    text-decoration: none;
}
@media (max-width: 1000px) {
    .main h1{
        font-size: 48px;
        line-height: 62px;
    }
    .main{
        padding: 0 20px;
        box-sizing: border-box;
    }
}
.emptyState{
    text-align: center;
    font-size: 15px;
    
}