.container{
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}
.container h1{
    max-width: 850px;
    font-weight: 600;
    font-size: 64px;
    line-height: 78px;
    margin: 0;
}
.container h1 span{
    color: #808080;
}
.introText{
    max-width: 750px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 20px;
}
.servicesGrid{
    width: 100%;
    display: grid;
    grid-template-columns:repeat(3, 1fr);
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 70px;
    margin-top: 70px;
}
.Item{
    width: 100%;
}
.Item img{
    width: 100%;
    height: 210px;
    object-fit: cover;
}
.contentContainer{
    padding-top: 20px;
}
.container h3{
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin: 0;
    line-height: 26px;
    max-width: 90%;
}
.contentContainer p{
    color: #4F4A4A;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    margin-top: 10px;
}
.Item button{
    height: 50px;
    width: fit-content;
    background-color: #F3F4F5;
    color:#2B2B2B;
    padding: 0 25px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    font-weight: 500;
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
}
@media (max-width: 1200px) {
    .container{
        padding: 50px 20px;
        box-sizing: border-box;
    }
}
@media (max-width: 1000px) {
    .servicesGrid{
        grid-template-columns: repeat(2, 1fr);
    }
    .container h1{
        font-size: 48px;
        line-height: 62px;
    }
}
@media (max-width: 600px) {
    .servicesGrid{
        grid-template-columns: repeat(1, 1fr);
    }
}