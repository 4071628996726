.container{
    height: fit-content;
    padding: 70px 20px;
    box-sizing: border-box;
    max-width: 1240px;
    margin-right: auto;
    margin-left: auto;
}
.container h1{
    font-size: 64px;
    font-weight: 600;
    line-height: 78px;   
    text-align: center;
    margin: 0;
}
.container h1 span{
    color: #808080;
}
.introText{
    max-width: 700px;
    text-align: center;
    line-height: 28px;
    font-weight: 400;
    margin: 0;
    margin-right: auto;
    margin-left: auto;
    margin-top: 15px;
}
.container button{
    height: 50px;
    width: fit-content;
    background-color: var(--gigmileRed);
    color: #fff;
    padding: 0 45px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    font-weight: 500;
    font-size: 14px;
    font-weight: 500;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.deliveryBusiness{
    width: 100%;
    margin-top: 47px;
}
.blocks{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin-top: 35px;
}
.BlockItem{
    height: fit-content;
    width: 100%;
    background: #F7F7F7;
    border-radius: 40px;
    padding: 32px;
    box-sizing: border-box;
}
.BlockItem h3{
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    width: 90%;
    line-height: 26px;
}
.BlockItem p{
    margin: 0;
    color: #4f4a4a;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 5px;
}
.BlockItemBlack{
    background: #191919;
}
.BlockItemBlack h3, .BlockItemBlack p{
    color: #fff;
}

/****** Responsiveness *******/
@media (max-width: 900px) {
    .blocks{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
    }
    .BlockItem{
        max-width: 350px;
    }
}
@media (max-width: 500px) {
    .BlockItem{
        min-width: 100%;
    }
}