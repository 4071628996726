.container{
    padding: 50px 0;
}
.main{
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}
.container h1{
    font-size: 64px;
    line-height: 78px;
    color: #000;
    margin: 0;
    font-weight: 600;
    max-width: 750px;
}
.container h1 span{
    color: #808080;
}
.introText{
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin-top: 20px;
    max-width: 700px;
}
.bottom_grid{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 30px;
    /* height: fit-content; */
    /* min-height: 400px; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.ValueItem{
    height: 100%;
    background: #F7F7F7;
    border-radius: 40px;
    padding: 30px;
    box-sizing: border-box;
    min-width: 310px;
    color: #000;
}
.ValueItem h3{
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    width: 90%;
    line-height: 26px;
}
.ValueItem p{
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 5px;
}
@media (max-width: 1000px) {
    .main{
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .container h1{
        font-size: 48px;
        line-height: 62px;
    }
    .bottom_grid{
        padding: 0 20px;
    }
}
@media (max-width: 800px) {
    .bottom_grid{
        padding: 0 20px;
        display: flex;
        flex-direction: column;
    }
}
.ValueItem:hover{
    animation: darken 0.5s ease forwards;
}
@keyframes darken {
    100%{background-color: #000; color: #fff;}
}