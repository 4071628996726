.container{
    min-height: 100vh;
    width: 100vw;
    background-color: #f7f7f7;
}
.main{
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    padding-top: 90px;
    padding-bottom: 110px;
    text-align: center;
}
.main_image{
    max-width: 100%;
}
.main h2{
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    max-width: 525px;
    margin-right: auto;
    margin-left: auto;
    line-height: 32px;
    margin-top: 50px;
}
.main p{
    margin: 0;
    font-size: 16px;
    line-height: 28px;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
}