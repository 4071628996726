.container{
    height: fit-content;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    padding: 67px 0;
}
.container h1{
    font-size: 64px;
    font-weight: 600;
    margin: 0;
    line-height: 78px;
}
.container h1 span{
    color: #808080;
}
.three_grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 30px;
    flex-wrap: wrap;
}
.ValueItem{
    height: 100%;
    background: #F7F7F7;
    border-radius: 30px;
    padding: 30px;
    box-sizing: border-box;
    min-width: 330px;
    color: #000;
}
.ValueItem h3{
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    width: 90%;
    line-height: 26px;
}
.ValueItem p{
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 5px;
}
.ValueItem div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    margin-top: 31px;
}
.ValueItem a{
    text-decoration: none;
    color: inherit;
}
.ValueItem_dark{
    background: #191919;
    color: #fff;
}
@media (max-width: 1000px) {
    .container h1{
        font-size: 48px;
        line-height: 62px;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .three_grid{
        display: flex;
        flex: row;
        flex-wrap: wrap;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .ValueItem{
        width: 100%;
    }
}
@media (max-width: 600px) {
    .ValueItem{
        width: 100%;
    }
}
.ValueItem:hover{
    animation: darken 0.5s ease forwards;
}
@keyframes darken {
    100%{color: #fff; background: #191919;}
}