.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.container p{
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    color: #000;
}
.container input, .container select, .container textarea{
    height: 47px;
    width: 100%;
    padding: 0 17px;
    box-sizing: border-box;
    border: 0.5px solid #C4C4C4;
    border-radius: 10px;
    background-color: #fff;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Graphik', sans-serif;
}
.container textarea{
    height: 110px;
    padding-top: 11px;
    font-family: 'Graphik', sans-serif;
}
.container input:focus, .container select:focus, .container textarea:focus{
    outline: none;
    border-color: var(--gigmileRed);
}