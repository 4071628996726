.container{
    width: 100vw;
    /* overflow: hidden; */
    text-align: center;
    position: relative;
    height: fit-content;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.container h1{
    font-weight: 600;
    font-size: 78px;
    line-height: 88px;
    margin: 0;
    margin-top: 40px;
    max-width: 820px;
    margin-right: auto;
    margin-left: auto;
}
.container p{
    max-width: 600px;
    font-size: 16px;
    line-height: 28px;
    margin: 0;
    margin-right: auto;
    margin-left: auto;
    font-weight: 400;
    margin-top: 15px;
}
.buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 15px;
}
.playButton{
    font-size: 14px;
    font-weight: 500;
    height: 50px;
    width: fit-content;
    padding: 0 20px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    border-radius: 15px;
}
.playButton:hover{
    background-color: #f5f5f5;
}
.partners{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    font-family: var(--graphikRegular);
    justify-content: center;
    margin-top: 32px;
    flex-wrap: wrap;
}
.partners p{
    margin: 0;
    padding-top: 8px;
}
.uber{
    max-width: 60px;
    opacity: 0.8;
}
@media (max-width: 1000px) {
    .main{
        padding: 0 20px;
        box-sizing: border-box;
    }
    .container h1{
        font-size: 48px;
        line-height: 62px;
    }
}