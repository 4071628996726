.container{
    height: fit-content;
    padding: 0 20px;
    padding-bottom: 150px;
    box-sizing: border-box;
    max-width: 1040px;
    margin-right: auto;
    margin-left: auto;
}
.container h1{
    font-size: 64px;
    line-height: 78px;
    color: #000;
    margin: 0;
    font-weight: 500;
    max-width: 750px;
}
.container h1 span{
    color: #808080;
}
.supporters_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 45px;
    margin-top: 15px;
}
.supporters_container img{
    max-width: 150px;
}
@media (max-width: 1000px) {
    .container h1{
        font-size: 48px;
        line-height: 62px;
    }
}