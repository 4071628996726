.container{
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 0;
    padding-bottom: 0;
    overflow: hidden;
}
.container h1{
    font-weight: 600;
    font-size: 64px;
    line-height: 88px;
    margin: 0;
    max-width: 790px;
}
.container p{
    max-width: 750px;
    font-size: 16px;
    line-height: 28px;
    margin: 0;
    font-family: 400;
    margin-top: 15px;
    margin-bottom: 20px;
}
.images{
    display: grid;
    grid-template-columns: 2fr 1.5fr 1fr 1.5fr;
    width: 100%;
    position: relative;
    bottom: -20px;
}
.images img{
    width: 100%;
}
.mainContainer{
    width: 100%;
    height: fit-content;
    position: relative;
    margin-top: 20px;
}
.thunderBolt1{
    position: absolute;
    top: 0;
    right: 50px;
    height: 50px;
}
.thunderBolt2{
    position: absolute;
    top: -50px;
    right: 90px;
    height: 50px;
}
@media (max-width: 1000px) {
    .main{
        padding: 0 20px;
        box-sizing: border-box;
    }
    .container h1{
        font-size: 48px;
        line-height: 62px;
    }
}