.container{
    max-width: 1200px;
    min-height: 725px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 60px;
    padding: 26px 21px;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}
.main{
    padding: 30px 47px;
    box-sizing: border-box;
    background-color: #fff;
    max-width: 700px;
}
.blog_title{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: #32A071;
}
.main h2{
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    margin: 0;
    margin-top: 10px;
}
.blog_intro{
    margin-top: 15px;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #4F4A4A;
}
.main ul{
    display: flex;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    color: #000;
}
.main ul li:nth-child(1){
    list-style: none;
    padding-right: 25px;
}
.main a{
    text-decoration: none;
    color: #000;
}
@media (max-width: 800px) {
    .main{
        width: 100%;
    }
}