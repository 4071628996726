.container{
    width: 100vw;
    overflow: hidden;
    position: relative;
    padding-bottom: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.main{
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}
.container h1{
    font-weight: 600;
    font-size: 78px;
    line-height: 88px;
    margin: 0;
    margin-top: 40px;
    max-width: 740px;
}
.container p{
    max-width: 700px;
    font-size: 16px;
    line-height: 28px;
    margin: 0;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 20px;
}
@media (max-width: 1200px) {
    .main{
        padding: 0 20px;
        box-sizing: border-box;
    }
    .container h1{
        font-size: 48px;
        line-height: 62px;
    }
}