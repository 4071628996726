.container{
    height: fit-content;
    padding: 80px 0;
    padding-bottom: 20px;
    width: 100vw;
    background-color: #f7f7f7;
}
.responsiveContainer{
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}
.top{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.top div{
    border-top: 1px solid #000;
    width: 137px;
}
.top h3{
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}
.main h1{
    max-width: 765px;
    font-size: 64px;
    font-weight: 600;
    margin: 0;
    line-height: 78px;
    margin-top: 5px;
}
.perksList{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    flex-direction: row;
    gap: 30px;
    margin-top: 50px;
    cursor: grab;
}
.PerkItem{
    background-color: #fff;
    min-width: 400px;
    border-radius: 40px;
    padding: 32px 25px;
    box-sizing: border-box;
    height: 100%;
    flex: 1;
}
.PerkItem img{
    height: 75px;
}
.PerkItem h3{
    font-weight: 600;
    margin: 0;
    font-size: 18px;
    margin-top: 4px;
}
.PerkItem p{
    margin: 0;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 10px;
    color: #4F4A4A;
}
@media (max-width: 1000px) {
    .responsiveContainer{
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .main h1{
        font-size: 48px;
        line-height: 62px;
    }
    .perksList{
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    .PerkItem{
        min-width: 100%;
    }
}