.container{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    z-index: 1000;
    display: grid;
    grid-template-columns: 1fr 300px;
}
.close{
    height: 100%;
    width: 100%;
}
.main{
    height: 100%;
    width: 100%;
    background-color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    animation: slideIn 0.5s ease forwards;
}
.hideDrawer{
    animation: slideOut 0.5s ease forwards;
}
.top{
    padding: 23px 0;
    display: flex;
    justify-content: flex-end;
}
.closeIcon{
    height: 40px;
    width: 40px;
    background-color: #f5f5f5;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.linksContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-end;
    margin-top: 30px;
}
.linksContainer a{
    text-decoration: none;
    color: #000;
    text-align: center;
}
.link{
    /* text-align: right; */
    font-size: 15px;
    font-weight: 500;
    padding: 5px 0;
    cursor: pointer;
    color: #000;
    border-radius: 7px;
}
.linksContainer button{
    height: 50px;
    width: 100%;
    background-color: var(--gigmileRed);
    color: #fff;
    padding: 0 25px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    font-weight: 500;
    font-size: 14px;
}
@keyframes slideIn {
    0%{transform: translateX(300px); opacity: 0;}
    100%{transform: translateX(0); opacity: 1;}
}
@keyframes slideOut {
    0%{transform: translateX(0); opacity: 1;}
    100%{transform: translateX(300px); opacity: 0;}
}