.container{
    background-color: #F7F7F7;
    padding: 85px 0px;
}
.main h1{
    max-width: 850px;
    font-weight: 600;
    font-size: 64px;
    line-height: 78px;
    margin: 0;
}
.mainPadded{
    max-width: 1240px;
    padding: 0 20px;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
}
.mainContainer{
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    gap: 35px;
    align-items: center;
    box-sizing: border-box;
}
.Item{
    min-width: 674px;
    height: 273px;
    background-color: #fff;
    border-radius: 40px;
    display: grid;
    grid-template-columns: 1fr 260px;
    overflow: hidden;
}
.contentContainer{
    height: 100%;
    width: 100%;
    padding: 36px;
    box-sizing: border-box;
}
.Item img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.Item h3{
    font-weight: 600;
    margin: 0;
    font-size: 18px;
}
.Item p{
    margin: 0;
    margin-top: 16px;
    color: #4F4A4A;
    line-height: 26px;
    font-size: 15px;
    font-weight: 400;
}
.Item h4{
    font-weight: 600;
    margin: 0;
    font-size: 16px;
    margin-top: 16px;
}
@media (max-width: 700px) {
    .mainContainer{
        flex-wrap: wrap;
    }
    .Item{
        min-width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column-reverse;
    }
    .Item img{
        height: 300px;
        width: 100%;
    }
    .main h1{
        font-size: 48px;
        line-height: 62px;
    }
}