.container{
    height: 50px;
    width: fit-content;
    background-color: var(--gigmileRed);
    color: #fff;
    padding: 0 25px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    font-weight: 500;
    font-size: 14px;
}
.containerWhite{
    height: 50px;
    width: fit-content;
    background-color: #fff;
    color: #000;
    padding: 0 25px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    font-weight: 500;
    font-size: 14px;
    font-weight: 500;
}