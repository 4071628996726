.container{
    height: 100vh;
    width: 100vw;
    background-color: var(--gigmileRed);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.main{
    max-width: 1240px;
    margin-right: auto;
    margin-left: auto;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
}
.top{
    display: flex;
    align-items: center;
    color: #fff;
    gap: 20px;
    font-size: 16px;
    font-weight: 600;
}
.top div{
    border-top: 1px solid #fff;
    width: 150px;
}
.main h1{
    font-size: 64px;
    font-weight: 600;
    color: #fff;
    margin: 0;
    line-height: 78px;
    max-width: 650px;
    margin-top: 15px;
}
.introText{
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    line-height: 24px;
    max-width: 625px;
    margin-top: 20px;
    color: #fff;
    margin-bottom: 20px;
}
@media (max-width: 1000px) {
    .main h1{
        font-size: 48px;
        line-height: 62px;
    }
}