.container{
    max-width: 800px;
    margin-top: 36px;
    margin-right: auto;
    margin-left: auto;
    background-color: #ffff;
    border-radius: 30px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.section{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.section h3{
    font-weight: 500;
    margin: 0;
    font-size: 18px;
}
.section p{
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
.section ul{
    margin: 0;
    padding: 0;
    padding-left: 20px;
}
.section li{
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
.button{
    text-align: center;
    margin-top: 10px;
}