.container{
    max-width: 800px;
    margin-top: 36px;
    margin-right: auto;
    margin-left: auto;
    background-color: #ffff;
    border-radius: 30px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 100px;
}
.container img{
    max-width: 300px;
}
.section h3{
    font-weight: 500;
    margin: 0;
    font-size: 18px;
}
.mainContent{
    margin: 0;
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
.extraLink{
    margin: 0;
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
.extraLink a{
    color: var(--gigmileRed);
    text-decoration: none;
}