.container{
    height: fit-content;
    max-width: 1240px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 600px;
    gap: 60px;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;

}
.contactUsForm{
    width: 100%;
    background: #FFFFFF;
    border-radius: 30px;
    filter: drop-shadow(0px 4px 84px rgba(0, 0, 0, 0.04));
    padding: 30px;
    box-sizing: border-box;
}
.contactUsForm h3{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}
.contactUsFormIntroText{
    font-size: 14px;
    font-weight: 400;
    color: #979797;
    margin: 0;
    line-height: 24px;
    margin-top: 10px;
}
.form{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.two_grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.errorMessage{
    margin: 0;
    color: red;
    margin-top: 20px;
}
.contactUsForm button{
    height: 50px;
    width: 100%;
    background-color: var(--gigmileRed);
    color: #fff;
    padding: 0 25px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    font-weight: 500;
    font-size: 14px;
    font-weight: 500;
    margin-top: 40px;
}
.main{
    width: 100%;
}
.main h1{
    font-weight: 600;
    margin: 0;
    font-size: 64px;
    line-height: 78px;
    color: #000;
}
.main p{
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    margin-top: 20px;
}
.partners{
    color: #0A2440;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin-top: 30px;
}
.partners div{
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.uber{
    max-width: 60px;
    opacity: 0.8;
}
@media (max-width: 1000px) {
    .container{
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
    .main h1{
        font-size: 48px;
        line-height: 62px;
    }
}