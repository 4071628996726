.container{
    max-width: 1240px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    padding: 0 20px;
    box-sizing: border-box;
    padding-bottom: 50px;
}
.container h1{
    font-size: 64px;
    line-height: 78px;
    color: #000;
    margin: 0;
    font-weight: 600;
    max-width: 795px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}
.container h1 span{
    color: #808080;
}
.introText{
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin-top: 20px;
}
.userContainer{
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    gap: 70px;
    align-items: center;
    justify-content: center;
    margin-top: 55px;
}
.Item{
    width: 200px;
}
.Item img{
    height: 200px;
    width: 100%;
    border-radius: 100px;
    object-fit: cover;
    background-color: peru;
}
.Item h3{
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    margin-top: 20px;
}
.Item p{
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #4F4A4A;
    margin-top: 5px;
}
@media (max-width: 1000px) {
    .container h1{
        font-size: 48px;
        line-height: 62px;
    }
}