.container{
    background-color: #f7f7f7;
    width: 100vw;
    overflow-x: hidden;
    min-height: 100vh;
}
.breadcrumbs{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 35px;
}
.breadcrumbs p{
    line-height: 19px;
    color: #4F4A4A;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
}
.breadcrumbs a{
    color: #4F4A4A;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
}
.container h1{
    max-width: 900px;
    font-size: 78px;
    font-weight: 600;
    line-height: 88px;
    margin: 0;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: 23px;
}
.location{
    text-align: center;
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    margin-top: 15px;
}
.buttons{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}
@media (max-width: 1000px) {
    .main{
        padding: 0 20px;
        box-sizing: border-box;
    }
    .container h1{
        font-size: 48px;
        line-height: 62px;
    }
}