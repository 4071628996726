.container{
    height: fit-content;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 500px 1fr;
    gap: 140px;
    padding: 100px 0px;
}
.mainContainer{
    height: 540px;
    width: 100%;
}
.phoneContainer{
    height: 540px;
    width: 100%;
    background: #EFEEEF;
    border-radius: 40px;
    display: none;
    align-items: flex-end;
    justify-content: center;
    padding-top: 60px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}
.displayPhone{
    display: flex;
    animation: slideIn 0.8s ease forwards;
}
.gammaAppImg{
    height: 100%;
}
.phoneContainer_canva{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.thunderBolt1{
    position: absolute;
    right: 10px;
    top: 40px;
}
.thunderBolt2{
    position: absolute;
    right: 30px;
    top: 5px;
}
.gammaAppNotification{
    position: absolute;
    right: 10px;
    bottom: 110px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.14), 0px 4px 34px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(17.6389px);
    animation: reverseScale 0.5s ease forwards;
}
.phoneContainer:hover .gammaAppNotification{
    animation: scale 0.5s ease forwards;
}
@keyframes scale {
    0%{transform: scale(1);}
    100%{transform: scale(1.1);}
}
@keyframes reverseScale {
    0%{transform: scale(1.1);}
    100%{transform: scale(1);}
}

/********* Faq styles **********/
.faqContainer{
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-bottom: 0.5px solid #979797;
    padding-bottom: 15px;
}
.FaqItem{
    border-top: 0.5px solid #979797;
    width: 100%;
}
.FaqItem_top{
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    padding-top: 15px;
    cursor: pointer;
}
.FaqItem_top div{
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.FaqItem_top div:hover{
    background-color: #f6f6f6;
}
.answer{
    margin-top: 5px;
    color: #4F4A4A;
    font-size: 15px;
    font-family: var(--graphikRegular);
    line-height: 24px;
    animation: slideDown 0.7s ease forwards;
}
@keyframes slideDown {
    0%{opacity: 0;}
    100%{opacity: 1;}
}
.extraText{
    margin: 0;
    margin-top: 35px;
    font-family: var(--graphikRegular);
    line-height: 28px;
    margin-bottom: 20px;
}

/************ Responsive stying **********/
@media (max-width: 1000px) {
    .container{
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 100px 20px;
        box-sizing: border-box;
    }
}
@keyframes slideIn {
    0%{transform: translateX(-100px); opacity: 0;}
    100%{transform: translateX(0px); opacity: 1;}
}