.header{
    padding: 23px 0;
    width: 100vw;
}
.header_main{
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 50px;
    align-items: center;
}
.header_main img{
    height: 37px;
    cursor: pointer;
}
.linkContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 50px;
}
.mainLinkscontainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 50px;
}
.linkContainer a{
    font-size: 16px;
    text-decoration: none;
    color: #000;
    font-weight: 400;
}
.linkContainerWhite a{
    color: #fff;
}
.hamburger{
    height: 40px;
    width: 40px;
    background-color: #f5f5f5;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;
}
@media (max-width: 1200px) {
    .header{
        padding: 23px 20px;
        box-sizing: border-box;
    }
    .hamburger{
        display: flex;
    }
    .mainLinkscontainer{
        display: none;
    }
}