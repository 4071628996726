.container{
    min-height: 100vh;
    width: 100vw;
    background-color: #000;
}
.main{
    max-width: 1240px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid #000;
    padding: 0 20px;
    box-sizing: border-box;
}
.main h1{
    font-weight: 600;
    color: #fff;
    margin: 0;
    font-size: 78px;
    line-height: 93px;
    margin-top: 85px;
    max-width: 1100px;
}
.main h1 span{
    color: var(--gigmileGreen);
}
.bottom{
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    gap: 31px;
    margin-top: 100px;
    justify-content: flex-end;
    align-items: flex-end;
}
.imagesContainer{
    height: 880px;
    width: 100%;
    position: relative;
}
.impactWoman{
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 10;
    position: absolute;
}

.bottomContext{
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #fff;
    padding-bottom: 44px;
    z-index: 200;
}
.bottomContext p{
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    margin-bottom: 20px;
}

/******** Vector positioning *******/
.vector2{
    position: absolute;
    top: -120px;
    right: -120px;
}
.vector1{
    position: absolute;
    top: 100px;
    left: -50px;
    z-index: 0;
}
.vector4{
    position: absolute;
    right: -50px;
    top: 200px;
}
.vector3{
    position: absolute;
    top: -150px;
    right: -160px;
}

@media (max-width: 1000px) {
    .main h1{
        font-size: 48px;
        line-height: 62px;
    }
    .bottom{
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
}
@media (max-width: 600px) {
    .imagesContainer{
        height: 400px;
    }
}